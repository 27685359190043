import { CircularProgress, makeStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { useDialog } from '@setvi/shared/providers/dialog';
import { Star, StarBorder, FolderOpen } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

import {
  GetResourceTypeName,
  downloadResource,
  normalizeChildResourcesToLink,
  normalizeResourceToLink
} from '@setvi/shared/utils';
import moment from 'moment';
import { ISMenuItem } from '@setvi/shared/components/sdropdown-menu/MenuItem';
import {
  AddFile,
  MagicIcon,
  SelectIcon,
  TrashIcon
} from '@setvi/shared/components/sicons';
import {
  DrawerContent,
  ResourceType,
  ResourceName,
  TextColor
} from '@setvi/shared/enums';

import { SConfirmDialog } from '@setvi/shared/components';
import { useSubscribedMutation } from '@setvi/shared/hooks';
import { useSnackbar } from 'notistack';
import {
  ChildResource,
  ResourceCategoryClass,
  Resource as ResourceProps
} from '@setvi/shared/interfaces';
import { ResourcesQueryKey, deleteUserResourceMutation } from 'Services';
import { ROUTES } from 'enumsV2';
import {
  AddPresentation,
  CreateLink,
  CreatePresentation,
  Download,
  Email,
  MoreInfo
} from 'Components/Shared/Icons';
import { useComposeEmail } from 'providersV2/compose-email/context';
import {
  GetLink,
  MoreInfo as MoreInfoDialog,
  ResourceChildren
} from '../../Dialogs';
import { PresentationDialog as CreatePresentationDialog } from '../../../../Workspaces/MyPresentations/Components/PresentationDialog';
import { useFavoritesActions } from '../useFavorites';
import { AddToPresentation } from '../Components';
import { DropdownItemsType } from './useResourcesDropdownItems';

const useStyles = makeStyles(theme => ({
  dropdownIconMarginTop: {
    marginTop: theme.spacing(1)
  },
  addPresentationIconFontSize: {
    fontSize: '1.6rem'
  },
  folderIcon: {
    height: 22
  },
  favoriteStar: {
    color: '#F08B0F'
  }
}));

interface GetMenuResourceOption {
  resource: ResourceProps;
  handleSendFullFile: (resource: ResourceProps) => void;
  handleSendSelected: (
    resource: ResourceProps,
    selected: ChildResource[]
  ) => void;
  handleCloseDropdown?: () => void | null;
}

enum DropdownItemsTypes {
  pdf = 'pdf',
  email = 'email',
  moreInfo = 'moreInfo',
  download = 'download',
  viewLink = 'viewLink',
  delete = 'delete',
  favorites = 'favorites',
  getLink = 'getLink',
  goToCategory = 'goToCategory',
  createPresentation = 'createPresentation',
  addToPresentation = 'addToPresentation',
  askAI = 'askAI'
}

type ItemType = keyof typeof DropdownItemsTypes;

interface Resource extends ISMenuItem {
  itemType?: ItemType;
}

export const useDropdownResource = (
  refetchLoading = false,
  setAksAIOpen: any
) => {
  const classes = useStyles();
  const { open } = useComposeEmail();
  const { isLoading, handleFavorites } = useFavoritesActions();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();

  const { isLoading: isDeleteLoading, mutateAsync: deleteResource } =
    useSubscribedMutation(deleteUserResourceMutation(), [
      ResourcesQueryKey.USER_RESOURCES,
      ResourcesQueryKey.FAVORITES_RESOURCES
    ]);

  const onEmailResource = useCallback(
    (resource: ResourceProps) => {
      open({
        linksData: normalizeResourceToLink(resource),
        drawerContent: DrawerContent.reviewLinks
      });
    },
    [open]
  );

  const onChildResourceSelected = useCallback(
    (parentResource: ResourceProps, selected: ChildResource[]) => {
      open({
        linksData: normalizeChildResourcesToLink(selected, parentResource.Name),
        drawerContent: DrawerContent.reviewLinks
      });
    },
    [open]
  );

  const onDownload = useCallback((resource: ResourceProps) => {
    downloadResource(resource?.DownloadURLWithSas, resource?.FileName);
  }, []);

  const handleMoreInfo = useCallback(
    (resource: ResourceProps) => {
      const informations = [
        {
          label: 'Resource type',
          value: GetResourceTypeName(resource?.ResourceTypeID)
        },
        {
          label: 'Last updated',
          value: `${moment
            .utc(resource?.ResourceLastChangedDate)
            .format('MM/DD/YYYY')} (${moment
            .utc(resource?.ResourceLastChangedDate)
            .local()
            .fromNow()})`
        },
        {
          label: 'Shareable',
          value: resource?.IsShareable ? 'Yes' : 'No'
        },
        {
          label: 'Category name',
          value: resource?.CategoryName
        }
      ];

      openDialog(
        <MoreInfoDialog
          informations={informations}
          thumbURL={resource?.ThumbURLWithSas || resource?.ThumbURL}
          breadcrumbs={resource?.Breadcrumbs}
          comment={resource?.Comment}
          onClose={() => closeDialog('more-info')}
        />,
        {
          id: 'more-info'
        }
      );
    },
    [openDialog, closeDialog]
  );

  const onGoToCategory = useCallback(
    (resource: ResourceProps) => {
      const link =
        resource.Breadcrumbs[0]?.ResourceCategoryClass ===
        ResourceCategoryClass.MY_RESOURCES
          ? ROUTES.WORKSPACES_FILES
          : ROUTES.RESOURCES;

      navigate(`${link}?categoryId=${resource?.CategoryID}`);
    },
    [navigate]
  );

  const onDeleteResource = useCallback(
    (resource: ResourceProps) => {
      openDialog(
        <SConfirmDialog
          title="Delete Resource"
          description="Are you sure you want to delete this resource?"
          type="delete"
          confirmText="Yes, Delete"
          loading={isDeleteLoading}
          onConfirm={() => {
            deleteResource({ resourceid: resource.ResourceID }).then(
              response => {
                if (response?.Errors) return;
                enqueueSnackbar('Resource has been deleted successfully.', {
                  variant: 'success'
                });
              }
            );
            closeDialog();
          }}
          onClose={closeDialog}
        />
      );
    },
    [openDialog, closeDialog, isDeleteLoading, deleteResource, enqueueSnackbar]
  );

  const getConditionalMenuOption = useCallback(
    ({
      resource,
      handleSendFullFile,
      handleSendSelected,
      handleCloseDropdown
    }: GetMenuResourceOption) => {
      const hasSubmenu =
        [ResourceType.Presentation, ResourceType.PdfDocument].includes(
          resource?.ResourceTypeID
        ) && resource.Pages > 1;

      const selectPageOption = resource.Pages > 1;

      return {
        closeOnClick: !hasSubmenu,
        ...(!hasSubmenu && {
          onClick: () => {
            handleSendFullFile(resource);
            handleCloseDropdown?.();
          }
        }),
        ...(hasSubmenu && {
          submenu: [
            {
              icon: <AddFile viewBox="0 0 23 23" />,
              text: `Add full ${
                resource.ResourceTypeID === ResourceType.PdfDocument
                  ? ResourceName.PdfDocument
                  : ResourceName.Presentation
              }`,
              subText: 'Add entire file with all pages',
              onClick: () => {
                handleSendFullFile(resource);
                handleCloseDropdown?.();
              }
            },
            ...(selectPageOption
              ? [
                  {
                    icon: <SelectIcon viewBox="0 0 23 23" />,
                    text: 'Select Pages',
                    subText: 'Add only relevant pages',
                    onClick: () =>
                      openDialog(
                        <ResourceChildren
                          confirm={selected =>
                            handleSendSelected(resource, selected)
                          }
                          resource={resource}
                        />
                      )
                  }
                ]
              : [])
          ]
        })
      };
    },
    [openDialog]
  );

  const getIDs = (resource: ResourceProps) => {
    const isPPT = resource.ResourceTypeID === ResourceType.Presentation;
    return !isPPT
      ? [resource.ResourceID]
      : resource.ChildResources.map(i => i.ItemID);
  };

  const createDropdownMenu = useCallback(
    (
      resource: ResourceProps,
      dropdownItems: DropdownItemsType = [],
      handleCloseDropdown?: () => void | null
    ): ISMenuItem[] => {
      const isNotDownloadable =
        !resource?.IsDownloadable || !resource?.DownloadURLWithSas;
      const isNotShareable = !resource?.IsShareable;
      const isMyResource =
        resource.ResourceClass === ResourceCategoryClass.MY_RESOURCES;

      const allItems: Resource[] = [
        {
          itemType: DropdownItemsTypes.email,
          icon: <Email />,
          text: 'Email',
          subText: 'Send this resource in an email',
          disabled: isNotShareable,
          tooltipText: isNotShareable && 'This resource is not shareable',
          ...getConditionalMenuOption({
            resource,
            handleSendFullFile: onEmailResource,
            handleSendSelected: onChildResourceSelected,
            handleCloseDropdown
          })
        },

        ...(resource.ID === 75876 || resource.ID === 117304
          ? [
              {
                itemType: DropdownItemsTypes.askAI,
                icon: <MagicIcon />,
                text: 'Ask AI',
                subText: 'Ask AI any questions about this resource',
                onClick: () => setAksAIOpen(true)
              }
            ]
          : []),

        {
          itemType: DropdownItemsTypes.favorites,
          icon: (
            <>
              {(isLoading || refetchLoading) && <CircularProgress size={20} />}
              {!isLoading && !refetchLoading && (
                <>
                  {resource.IsFavorite ? (
                    <Star
                      className={classes.favoriteStar}
                      htmlColor={TextColor.Grey}
                    />
                  ) : (
                    <StarBorder htmlColor={TextColor.Grey} />
                  )}
                </>
              )}
            </>
          ),
          disabled: isLoading || refetchLoading,
          text: resource.IsFavorite
            ? 'Remove from Favorites'
            : 'Add to Favorites',
          subText: resource.IsFavorite
            ? 'Remove this resource from your favorites'
            : 'Add this resource to your favorites',
          closeOnClick: false,
          onClick: () =>
            handleFavorites(resource.ResourceID, resource.IsFavorite)
        },
        {
          itemType: DropdownItemsTypes.download,
          icon: <Download />,
          text: 'Download',
          subText: 'Download this resource to your device',
          onClick: () => onDownload(resource),
          disabled: isNotDownloadable,
          tooltipText: isNotDownloadable && 'This resource is not downloadable'
        },
        {
          itemType: DropdownItemsTypes.createPresentation,
          icon: <CreatePresentation />,
          text: 'Create Presentation',
          subText: 'Create a presentation with this resource',
          ...getConditionalMenuOption({
            resource,
            handleSendFullFile: () =>
              openDialog(
                <CreatePresentationDialog
                  withResources={getIDs(resource)}
                  onClose={() => closeDialog('create-presentation')}
                />,
                {
                  id: 'create-presentation'
                }
              ),
            handleSendSelected: (_, selected) =>
              openDialog(
                <CreatePresentationDialog
                  withResources={selected.map(i => i.ItemID)}
                  onClose={() => closeDialog('create-presentation')}
                />,
                {
                  id: 'create-presentation'
                }
              )
          })
        },
        {
          itemType: DropdownItemsTypes.addToPresentation,
          icon: <AddPresentation />,
          text: 'Add to Presentation',
          subText: 'Add this resource to an existing presentation.',
          ...getConditionalMenuOption({
            resource,
            handleSendFullFile: () =>
              openDialog(
                <AddToPresentation
                  IDs={getIDs(resource)}
                  onClose={() => closeDialog('add-to-presentation')}
                />,
                {
                  id: 'add-to-presentation'
                }
              ),
            handleSendSelected: (_, selected) =>
              openDialog(
                <AddToPresentation
                  IDs={selected.map(i => i.ItemID)}
                  onClose={() => closeDialog('add-to-presentation')}
                />,
                {
                  id: 'add-to-presentation'
                }
              )
          })
        },
        {
          itemType: DropdownItemsTypes.getLink,
          icon: <CreateLink fontSize="small" />,
          text: 'Get link',
          subText: 'Copy a link to paste anywhere',
          onClick: () =>
            openDialog(
              <GetLink
                resourceId={resource.ID}
                onClose={() => closeDialog('get-link')}
              />,
              {
                id: 'get-link'
              }
            ),
          disabled: isNotShareable,
          tooltipText: isNotShareable && 'This resource is not shareable'
        },
        {
          itemType: DropdownItemsTypes.goToCategory,
          icon: <FolderOpen />,
          text: 'Go to Category',
          subText: 'Jump into the main category',
          onClick: () => onGoToCategory(resource)
        },
        isMyResource && {
          itemType: DropdownItemsTypes.delete,
          icon: <TrashIcon />,
          text: 'Delete',
          subText: 'Delete this resource',
          onClick: () => onDeleteResource(resource),
          color: 'danger'
        },
        {
          itemType: DropdownItemsTypes.moreInfo,
          icon: <MoreInfo />,
          text: 'More Info',
          subText: 'More details about this resource',
          onClick: () => handleMoreInfo(resource)
        }
      ];

      if (dropdownItems.length) {
        return allItems.filter(dropdownItem =>
          dropdownItems.includes(dropdownItem.itemType)
        );
      }

      return allItems;
    },
    [
      getConditionalMenuOption,
      onEmailResource,
      onChildResourceSelected,
      isLoading,
      refetchLoading,
      classes.favoriteStar,
      setAksAIOpen,
      handleFavorites,
      onDownload,
      openDialog,
      closeDialog,
      onGoToCategory,
      onDeleteResource,
      handleMoreInfo
    ]
  );

  return {
    createDropdownMenu
  };
};
