import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ROUTES } from 'enumsV2';
import App from 'App';
import RouterModal from 'Providers/RouterModal';
import { RealmProvider } from 'Providers/MongoRealmProvider';

import { GuestLayout, MainLayout, SetviGuestLayout } from 'Layouts';
import ProtectedProducts from 'pages/products/components/protected-products';
import { NonAuthorizedRoutes } from './non-authorized';
import { AuthorizedRoutes, ModuleAuthorizedRoutes } from './authorized';

// Lazy loading
import * as LazyPage from './lazy-loading/pages';
import { LazyLoader } from './lazy-loading/loader';
import { ErrorPage } from './error-page';

const lazyLoadElement = (component: JSX.Element) => (
  <Suspense fallback={<LazyLoader />}>{component}</Suspense>
);

const routes = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        element: <AuthorizedRoutes />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                errorElement: (
                  <ErrorPage
                    src="/images/error-page.svg"
                    title="Uh oh, Something went wrong!"
                    description="Seems like something broke, Please use below button to navigate back."
                  />
                ),
                children: [
                  {
                    path: ROUTES.HOME,
                    element: lazyLoadElement(<LazyPage.Home />)
                  },
                  {
                    path: ROUTES.CAMPAIGNS,
                    children: [
                      {
                        path: ROUTES.MY_CAMPAIGNS,
                        element: lazyLoadElement(<LazyPage.MyCampaignsPage />)
                      },
                      {
                        path: ROUTES.MY_CAMPAIGNS_CREATE,
                        element: lazyLoadElement(<LazyPage.CreateCampaign />)
                      },
                      {
                        path: ROUTES.MY_CAMPAIGNS_DETAIL,
                        element: lazyLoadElement(<LazyPage.Details />)
                      },
                      {
                        path: ROUTES.MY_CAMPAIGNS_DETAIL_FOLLOW_UP,
                        element: lazyLoadElement(<LazyPage.FollowUp />)
                      },
                      // COMPANY CAMPAIGNS //
                      {
                        path: ROUTES.COMPANY_CAMPAIGNS,
                        element: lazyLoadElement(<LazyPage.CompanyCampaign />)
                      }
                    ]
                  },
                  {
                    path: ROUTES.ANALYITICS,
                    element: lazyLoadElement(<LazyPage.Analytics />)
                  },
                  {
                    path: ROUTES.RESOURCES,
                    element: lazyLoadElement(<LazyPage.Resources />)
                  },
                  {
                    path: ROUTES.FAVORITES,
                    element: lazyLoadElement(<LazyPage.Favorites />)
                  },
                  {
                    path: ROUTES.WORKSPACES,
                    children: [
                      {
                        element: (
                          <ModuleAuthorizedRoutes module="MyResourcesAllowed" />
                        ),
                        children: [
                          {
                            path: ROUTES.WORKSPACES_FILES,
                            element: lazyLoadElement(<LazyPage.MyFiles />)
                          }
                        ]
                      },
                      {
                        path: ROUTES.WORKSPACES_PRESENTATIONS,
                        element: lazyLoadElement(<LazyPage.MyPresentation />)
                      },
                      {
                        path: ROUTES.WORKSPACES_PRESENTATION_DETAILS,
                        element: lazyLoadElement(
                          <LazyPage.PresentationDetails />
                        )
                      },
                      {
                        path: ROUTES.WORKSPACES_TEMPLATE_DETAILS,
                        element: lazyLoadElement(<LazyPage.TemplateDetails />)
                      },
                      {
                        path: ROUTES.WORKSPACES_SNIPPETS,
                        element: lazyLoadElement(
                          <LazyPage.WorkspacesSnippets />
                        )
                      },
                      {
                        path: ROUTES.WORKSPACES_CONTACTS_GROUPS,
                        element: lazyLoadElement(
                          <LazyPage.ContactsAndGroups />
                        ),
                        children: [
                          {
                            index: true,
                            element: lazyLoadElement(<LazyPage.Contacts />)
                          },
                          {
                            path: ROUTES.WORKSPACES_GROUPS,
                            element: lazyLoadElement(<LazyPage.Groups />)
                          }
                        ]
                      },
                      {
                        path: ROUTES.WORKSPACES_DIGITAL_ROOMS,
                        element: lazyLoadElement(<LazyPage.DigitalRooms />)
                      }
                    ]
                  },
                  {
                    path: ROUTES.EMAIL_QUEUE,
                    element: lazyLoadElement(<LazyPage.EmailQueue />)
                  },
                  {
                    path: ROUTES.OUTBOX,
                    element: lazyLoadElement(<LazyPage.Outbox />),
                    children: [
                      {
                        index: true,
                        element: lazyLoadElement(<LazyPage.Emails />)
                      },
                      {
                        path: ROUTES.OUTBOX_GET_LINK,
                        element: lazyLoadElement(<LazyPage.OutboxGetLink />)
                      },
                      {
                        path: ROUTES.OUTBOX_OUTLOOK,
                        element: lazyLoadElement(<LazyPage.Outlook />)
                      },
                      {
                        path: ROUTES.OUTBOX_CAMPAIGN,
                        element: lazyLoadElement(<LazyPage.OutboxMyCampaigns />)
                      }
                    ]
                  },
                  {
                    path: ROUTES.OUTBOX_OUTLOOK_DETAILS,
                    element: lazyLoadElement(<LazyPage.OutlookDetails />),
                    children: [
                      {
                        index: true,
                        element: lazyLoadElement(
                          <LazyPage.OutlookEmailDetails />
                        )
                      },
                      {
                        path: ROUTES.OUTBOX_OUTLOOK_VIEWS,
                        element: lazyLoadElement(<LazyPage.OutlookViews />)
                      }
                    ]
                  },
                  {
                    path: ROUTES.OUTBOX_EMAIL_DETAILS_MULTIPLE,
                    element: lazyLoadElement(
                      <LazyPage.MultipleRecipientEmailDetails />
                    ),
                    children: [
                      {
                        path: ROUTES.OUTBOX_EMAIL_DETAILS_MULTIPLE_OVERVIEW,
                        element: lazyLoadElement(<LazyPage.Overview />)
                      },
                      {
                        path: ROUTES.OUTBOX_EMAIL_DETAILS_MULTIPLE_EMAIL_DETAILS,
                        element: lazyLoadElement(<LazyPage.EmailDetails />)
                      }
                    ]
                  },
                  {
                    path: ROUTES.OUTBOX_EMAIL_DETAILS_SINGLE,
                    element: lazyLoadElement(
                      <LazyPage.SingleRecipientEmailDetails />
                    )
                  },
                  {
                    path: ROUTES.VET_SUITE,
                    element: (
                      <RealmProvider>
                        {lazyLoadElement(<LazyPage.VetSuite />)}
                      </RealmProvider>
                    )
                  },
                  {
                    path: ROUTES.DIGITAL_SHOWROOM,
                    element: (
                      <RealmProvider>
                        {lazyLoadElement(<LazyPage.VetSuiteDigitalShowroom />)}
                      </RealmProvider>
                    )
                  },
                  // {/* ******* REPORTING ******** */}
                  {
                    path: ROUTES.REPORTING_DETAILS,
                    element: <LazyPage.ReportingDetails />
                  },
                  // {/* ******* ADMIN ******** */}
                  {
                    path: ROUTES.ADMIN,
                    children: [
                      {
                        path: ROUTES.ADMIN_CAMPAIGNS,
                        element: lazyLoadElement(<LazyPage.AdminCampaigns />),
                        children: [
                          {
                            index: true,
                            element: lazyLoadElement(
                              <LazyPage.AdminCampaignsTable />
                            )
                          },
                          {
                            path: ROUTES.ADMIN_CAMPAIGNS_DETAILS,
                            element: lazyLoadElement(
                              <LazyPage.AdminCampaignDetails />
                            ),
                            children: [
                              {
                                index: true,
                                element: lazyLoadElement(
                                  <LazyPage.AdminCampaignDetailsContent />
                                )
                              },
                              {
                                path: ROUTES.ADMIN_CAMPAIGNS_DETAILS_RECIPIENTS,
                                element: lazyLoadElement(
                                  <LazyPage.AdminCampaignDetailsRecipients />
                                )
                              }
                            ]
                          },
                          {
                            path: ROUTES.ADMIN_CAMPAIGNS_DETAILS_USER_CAMPAIGNS,
                            element: lazyLoadElement(<LazyPage.Details />)
                          },
                          {
                            path: ROUTES.ADMIN_CAMPAIGNS_CREATE,
                            element: lazyLoadElement(
                              <LazyPage.CreateAdminCampaign />
                            )
                          },
                          {
                            path: ROUTES.ADMIN_CAMPAIGNS_EDIT,
                            element: lazyLoadElement(
                              <LazyPage.UpdateAdminCampaign />
                            )
                          }
                        ]
                      },
                      {
                        path: ROUTES.ADMIN_RESOURCES,
                        element: lazyLoadElement(<LazyPage.AdminResources />),
                        children: [
                          {
                            path: ROUTES.ADMIN_RESOURCES_CREATE_CATEGORY,
                            element: (
                              <RouterModal
                                route={ROUTES.ADMIN_RESOURCES_CREATE_CATEGORY}
                                fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                                {lazyLoadElement(<LazyPage.CreateCategory />)}
                              </RouterModal>
                            )
                          },
                          {
                            path: ROUTES.ADMIN_RESOURCES_EDIT_CATEGORY,
                            element: (
                              <RouterModal
                                route={ROUTES.ADMIN_RESOURCES_EDIT_CATEGORY}
                                fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                                {lazyLoadElement(<LazyPage.EditCategory />)}
                              </RouterModal>
                            )
                          },
                          {
                            path: ROUTES.ADMIN_RESOURCES_ADD_SINGLE,
                            element: (
                              <RouterModal
                                route={ROUTES.ADMIN_RESOURCES_ADD_SINGLE}
                                fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                                {lazyLoadElement(
                                  <LazyPage.AddSingleResource />
                                )}
                              </RouterModal>
                            )
                          },
                          {
                            path: ROUTES.ADMIN_RESOURCES_EDIT_SINGLE,
                            element: (
                              <RouterModal
                                route={ROUTES.ADMIN_RESOURCES_EDIT_SINGLE}
                                fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                                {lazyLoadElement(
                                  <LazyPage.EditSingleResource />
                                )}
                              </RouterModal>
                            )
                          },
                          {
                            path: ROUTES.ADMIN_RESOURCES_ADD_BULK,
                            element: (
                              <RouterModal
                                route={ROUTES.ADMIN_RESOURCES_ADD_BULK}
                                fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                                {lazyLoadElement(<LazyPage.AddBulkResource />)}
                              </RouterModal>
                            )
                          },
                          {
                            path: ROUTES.ADMIN_RESOURCES_ADD_ZIP,
                            element: (
                              <RouterModal
                                route={ROUTES.ADMIN_RESOURCES_ADD_ZIP}
                                fallbackRoute={ROUTES.ADMIN_RESOURCES}>
                                {lazyLoadElement(<LazyPage.AddZipResource />)}
                              </RouterModal>
                            )
                          }
                        ]
                      },
                      {
                        path: ROUTES.ADMIN_USERS,
                        element: lazyLoadElement(<LazyPage.AdminUsers />),
                        children: [
                          {
                            path: ROUTES.ADMIN_USERS,
                            element: lazyLoadElement(
                              <LazyPage.AdminUsersHome />
                            ),
                            children: [
                              {
                                path: ROUTES.ADMIN_USERS_CREATE,
                                element: (
                                  <RouterModal
                                    route={ROUTES.ADMIN_USERS_CREATE}
                                    fallbackRoute={ROUTES.ADMIN_USERS}>
                                    {lazyLoadElement(
                                      <LazyPage.AdminUserCreate />
                                    )}
                                  </RouterModal>
                                )
                              },
                              {
                                path: ROUTES.ADMIN_USERS_EDIT,
                                element: (
                                  <RouterModal
                                    route={ROUTES.ADMIN_USERS_EDIT}
                                    fallbackRoute={ROUTES.ADMIN_USERS}>
                                    {lazyLoadElement(
                                      <LazyPage.AdminUserEdit />
                                    )}
                                  </RouterModal>
                                )
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: ROUTES.SETTINGS,
                    element: lazyLoadElement(<LazyPage.Settings />)
                  },
                  {
                    element: (
                      <ErrorPage
                        src="/images/404-page.svg"
                        title="Oops! Looks like you got lost"
                        description="Let’s get you back home by clicking the below button."
                      />
                    ),
                    path: ROUTES.NOT_FOUND
                  }
                ]
              },
              {
                path: ROUTES.PRODUCTS,
                element: (
                  <ProtectedProducts>
                    {lazyLoadElement(<LazyPage.Products />)}
                  </ProtectedProducts>
                )
              },
              {
                path: ROUTES.SETTINGS,
                element: lazyLoadElement(<LazyPage.Settings />)
              }
            ]
          },
          {
            children: [
              {
                path: ROUTES.PRODUCT_DETAILS,
                element: (
                  <RealmProvider>
                    {lazyLoadElement(<LazyPage.ProductDetails />)}
                  </RealmProvider>
                )
              }
            ]
          }
        ]
      },
      {
        element: <NonAuthorizedRoutes />,
        children: [
          {
            element: window.location.origin.includes('middleby') ? (
              <GuestLayout />
            ) : (
              <SetviGuestLayout />
            ),
            children: [
              {
                path: ROUTES.LOGIN,
                element: lazyLoadElement(<LazyPage.Authenticate />)
              }
            ]
          }
        ]
      },
      {
        element: window.location.origin.includes('middleby') ? (
          <GuestLayout />
        ) : (
          <SetviGuestLayout />
        ),
        children: [
          {
            path: ROUTES.VERIFY_EMAIL,
            element: lazyLoadElement(<LazyPage.EmailVerification />)
          }
        ]
      }
    ]
  }
]);

export const AppRoutes = () => (
  <RouterProvider future={{ v7_startTransition: true }} router={routes} />
);
