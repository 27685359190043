// Generated by https://quicktype.io
export interface User {
  ID: number;
  Password: string;
  FirstName: string;
  LastName: string;
  FullName: string;
  Email: string;
  Role: number;
  DateAdded: string;
  LastActiveDate: string;
  IsActive: boolean;
  Token: string;
  DeviceID: string;
  UUID: null;
  EnablePushNotification: boolean;
  CompanyID: number;
  CompanyName: string;
  Photo: null;
  ContactNumber: string;
  Deleted: boolean;
  TimeZoneInfo: TimeZoneInfo;
  TimeZone: string;
  LanguagePreferred: string;
  TimeZoneAbbrev: string;
  mobileVersion: number;
  LocalizedStatus: null;
  SoftwareVersion: null;
  NotifyEmailOpen: boolean;
  NotifyLinkView: boolean;
  NotifyFirstLinkView: boolean;
  NotificationHubDeviceID: string;
  NotificationHubGCMID: null;
  EnableGCM: boolean;
  KloudlsessAccountID: null;
  SalesforceEmail: string;
  SalesforceUserID: string;
  KloudlessZohoAccountID: null;
  ZohoEmail: null;
  ZohoUserID: null;
  IPhoneVersion: null;
  EmailSyncAccessToken: string;
  EmailSyncAccountID: string;
  EmailSyncAlternativeEmail: string;
  UserCRMbccEmail: string;
  Resources: null;
  ResourceCategories: null;
  Company: Company;
  Messages: null;
  ExternalUserDomainId: number;
  EmailVerified: boolean;
  RoleName: string;
  Status: string;
  ActivityStatus: string;
  TokenStatus: string;
  StandardAdminRights: StandardAdminRights[];
}

export interface Company {
  ID: number;
  Name: string;
  BackgroundFileName: string;
  Activity: Activity;
  BackgroundLastChanged: string;
  licenceAccepted: boolean;
  licenceAcceptedDate: string;
  HasDocForBox: null;
  LastDataChangedDate: string;
  CustomerID: null;
  MyResourcesAllowed: boolean;
  FocusSwitch: number;
  InBlob: boolean;
  MovingTo: null;
  maxUsersAllowed: number;
  AllowWebApp: AllowIPhone;
  AllowBasicSync: number;
  auth0SSOConn: null;
  iphonelogourl: string;
  AllowIPhone: AllowIPhone;
  NumRequired: number;
  EnableSyncOptions: AllowIPhone;
  SntBackgroundUrl: string;
  iPhoneBackgroundUrl: string;
  webAppLogoUrl: string;
  EmailSyncCredentials: EmailSyncCredentials;
  CompanySettingsList: CompanySettingsList[];
  _numberOfUsers: number;
  numberOfUsers: number;
  BeShareable: number;
  AllowInternallDownloads: number;
  AllowExternalDownloads: number;
  AssignAllUsersGroups: number;
  WebIntegrationAllowed: boolean;
}

export interface StandardAdminRights {
  AddedBy: number;
  DateAdded: string;
  ID: number;
  IsActive: boolean;
  IsUserAllowed: boolean;
  Name: string;
  ParentId: number;
}

export interface Activity {
  Active: number;
  ActiveText: string;
}

export interface AllowIPhone {
  Allowed: number;
  AllowedText: string;
}

export interface CompanySettingsList {
  ID: number;
  CompanyID: number;
  CompanySettingsTypeID: number;
  Value: string;
  AddedBy: number;
  DateAdded: string;
  LastModifiedBy: number;
  LastModifiedDate: string;
}

export enum CompanySettingsTypeID {
  FocusSwitch = 1,
  EnableSyncOptions = 2,
  AllowBasicSync = 3,
  NumberRequired = 4,
  MyResources = 5,
  IsShareable = 6,
  AllowInternalDownloads = 7,
  AllowExternalDownloads = 8,
  AssignAllUsersGroups = 9,
  WebIntegrationAllowed = 10,
  LogoUrl = 11,
  BackgroundUrl = 12,
  iPhoneLogoUrl = 13,
  SntBackgroundUrl = 14,
  iPhoneBackgroundUrl = 15,
  HomePageStyle = 16,
  WebAppLogoUrl = 17,
  DefaultWebAPPiPadBanner = 18,
  DefaultMobileBanner = 19,
  CustomCNameForShareLinks = 20,
  SubjectEngageEmail = 21,
  DisplayNameEngageEmail = 22,
  CRMTaskUrl = 25,
  CRMTaskApiKey = 26,
  CRMClientUrl = 27,
  CRMClientApiKey = 28,
  AllowExternalDomainUsers = 29,
  AkeneoClientID = 30,
  AkeneoSecret = 31,
  AkeneoUsername = 32,
  AkeneoPassword = 33,
  AkeneoUrl = 34,
  AkeneoUser = 35,
  AkeneoSupportEmail = 36,
  EmailSenderStatus = 37,
  CompanyType = 38,
  SalesforceCDC_TokenURL = 39,
  SalesforceCDC_ConsumerKey = 40,
  SalesforceCDC_ConsumerSecret = 41,
  AtlasMongoProductAppId = 42,
  AtlasMongoProductDBName = 43,
  AlgoliaProductAppId = 44,
  AlgoliaProductIndex = 45,
  AlgoliaProductAdminApiKey = 46,
  AlgoliaProductSearchApiKey = 47,
  PricingStrategy = 48,
  InventoryStrategy = 49
}

export enum CompanyProductUserType {
  Distributor = '0',
  Manufacturer = '1'
}

export interface EmailSyncCredentials {
  ID: number;
  CompanyID: number;
  EmailSyncAppID: string;
  EmailSyncAppSecret: string;
  ProviderID: number;
  ProviderName: string;
  ProviderType: number;
  ImapHost: string;
  ImapPort: string;
  SmtpHost: string;
  SmtpPort: string;
  SSLRequired: boolean;
}

export interface TimeZoneInfo {
  Id: string;
  DisplayName: string;
  StandardName: string;
  DaylightName: string;
  BaseUtcOffset: { [key: string]: number };
  SupportsDaylightSavingTime: boolean;
}

export interface GetCrmDataQuery<T> {
  Status: string;
  Data: Data<T>;
  Error: null;
}

export interface Data<T> {
  totalSize: number;
  done: boolean;
  records: T[];
}

export interface Lead {
  attributes: Attributes;
  Id: string;
  FirstName: null | string;
  LastName: null | string;
  Email: string;
  Title: null | string;
  Company: null | string;
  Salutation: null | string;
  Phone: null | string;
}

export interface Attributes {
  type: string;
  url: string;
}

export interface GetCompanyDataQuery {
  Data: CompanyData[];
  Total: number;
  AggregateResults: null;
  Errors: null;
}

export interface CompanyData {
  ID: number;
  Name: string;
  BackgroundFileName: string;
  Activity: Activity;
  BackgroundLastChanged: string;
  licenceAccepted: boolean;
  licenceAcceptedDate: string;
  HasDocForBox: null;
  LastDataChangedDate: string;
  CustomerID: null;
  MyResourcesAllowed: boolean;
  FocusSwitch: number;
  InBlob: boolean;
  MovingTo: null;
  maxUsersAllowed: number;
  AllowWebApp: Allow;
  AllowBasicSync: number;
  auth0SSOConn: null;
  iphonelogourl: string;
  AllowIPhone: Allow;
  NumRequired: number;
  EnableSyncOptions: Allow;
  SntBackgroundUrl: string;
  iPhoneBackgroundUrl: string;
  webAppLogoUrl: string;
  EmailSyncCredentials: EmailSyncCredentials;
  CompanySettingsList: CompanySettingsList[];
  Settings?: {
    [key: CompanySettingsList['CompanySettingsTypeID']]: CompanySettingsList;
  };
  _numberOfUsers: number;
  numberOfUsers: number;
  BeShareable: number;
  AllowInternallDownloads: number;
  AllowExternalDownloads: number;
  AssignAllUsersGroups: number;
  WebIntegrationAllowed: boolean;
}

export interface Allow {
  Allowed: number;
  AllowedText: string;
}

export interface GetCompanyModulesQuery {
  Data: CompanyModule[];
  Total: number;
  AggregateResults: null;
  Errors: null;
}

export interface CompanyModule {
  ID: number;
  CompanyID: number;
  ModuleID: number;
  CustomModuleID: null;
  ModuleName: string;
  ZipFileName: string;
  IconFileName: string;
  DateCreated: string;
  CustomModuleDateLastModified: null;
}

export enum CompanyModuleId {
  Resources = 1,
  Products = 2,
  Presentations = 3,
  Broadcast = 4,
  DocumentCenter = 5,
  SalesForce = 6,
  PPTExport = 7,
  EmailSync = 8,
  WebIntegration = 9,
  Zoho = 10,
  ExternalDomainUsers = 11,
  Akeneo = 12,
  Orders = 13,
  Quotes = 14,
  Intelligence = 15
}
