import { useMemo } from 'react';

import { SDrawerContent, SDrawer } from '@setvi/shared/components';
import { AuxProps } from '@setvi/shared/interfaces';

import { Header, Minimazed } from 'providersV2/components';
import { Container, ExpandedWrapper } from 'providersV2/style';
import { useAppContext } from 'Providers/AppProvider/AppContext';

import { DrawerContent } from '@setvi/shared/enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ComposeEmailContext } from './context';
import { EmailForm } from './components';
import { useCompose } from './hook';

export enum EmailDimensions {
  MAX_HEIGHT_EXPANDED = 585,
  MAX_HEIGHT_MINIMIZED = 50,
  MAX_WIDTH_EXPANDED = 690,
  MAX_WIDTH_MINIMIZED = 350
}

export const ComposeEmailProvider = ({ children }: AuxProps) => {
  const { sasToken, algoliaKey, user, companyData } = useAppContext();

  const {
    link,
    links,
    title,
    values,
    isLoading,
    editorNode,
    isExpanded,
    isDrawerOpen,
    rightMargin,
    initLinkOptions,
    initProductsOptions,
    openComposeEmail,
    minmizedElements,
    headerElements,
    currentDrawerContent,

    open,
    close,
    onSubmit,
    setValues,
    insertLink,
    removeLink,
    toggleDrawer,
    setOpenLinkId,
    setIsExpanded,
    setEditorNode,
    handleSnipset,
    closeComposeEmail,
    modifyRecipientList,
    setCurrentDrawerContent,
    modifyRecipientListWithListOfContacts
  } = useCompose();

  const contextValue = useMemo(
    () => ({
      values,
      links,
      open,
      close,
      editorNode
    }),
    [values, links, open, close, editorNode]
  );
  const flags = useFlags();

  const drawerOptions = [
    DrawerContent.favorites,
    ...(flags?.resources ? [DrawerContent.resources] : []),
    ...(flags?.myFiles && companyData?.MyResourcesAllowed
      ? [DrawerContent.myResources]
      : []),
    ...(flags?.myPresentations ? [DrawerContent.presentations] : []),
    ...(flags?.myFiles ? [DrawerContent.uploadFile] : []),
    DrawerContent.webLink,
    ...(flags?.snippets ? [DrawerContent.snippet] : [])
  ];

  return (
    <ComposeEmailContext.Provider value={contextValue}>
      {children}
      {openComposeEmail && (
        <>
          <Container
            right={0}
            style={{
              zIndex: 999,
              marginRight: rightMargin,
              maxHeight: isExpanded
                ? EmailDimensions.MAX_HEIGHT_EXPANDED
                : EmailDimensions.MAX_HEIGHT_MINIMIZED,
              maxWidth: isExpanded
                ? EmailDimensions.MAX_WIDTH_EXPANDED
                : EmailDimensions.MAX_WIDTH_MINIMIZED
            }}>
            <Minimazed
              title={title}
              onWrapperClick={() => setIsExpanded(true)}
              elements={minmizedElements}
              display={!isExpanded ? 'flex' : 'none'}
            />

            <ExpandedWrapper display={isExpanded ? 'flex' : 'none'}>
              <Header title={title} elements={headerElements} />
              <EmailForm
                links={links}
                values={values}
                loading={isLoading}
                drawerType={currentDrawerContent}
                initLinkOptions={initLinkOptions}
                initProductsOptions={initProductsOptions}
                onSubmit={onSubmit}
                setValues={setValues}
                toggleDrawer={toggleDrawer}
                setEditorNode={setEditorNode}
                closeComposeEmail={closeComposeEmail}
              />
            </ExpandedWrapper>
          </Container>

          <SDrawer open={isDrawerOpen}>
            <SDrawerContent
              userId={user.ID}
              link={link}
              links={links}
              sasToken={sasToken}
              algoliaKey={algoliaKey}
              handleBack={() => setOpenLinkId(null)}
              handleEdit={item => setOpenLinkId(item?.Placeholder)}
              hideBackButton={false}
              hideEditLink
              showContent={currentDrawerContent}
              selectedContacts={contextValue.values.sendTo}
              modifyRecipientList={modifyRecipientList}
              modifyRecipientListWithListOfContacts={
                modifyRecipientListWithListOfContacts
              }
              salesforceUser={{
                SalesforceEmail: user.SalesforceEmail,
                SalesforceUserID: user.SalesforceUserID
              }}
              handleClose={() => {
                toggleDrawer();
                setOpenLinkId(null);
              }}
              handleInsert={insertLink}
              insertMessage={handleSnipset}
              handleRemoveLink={removeLink}
              setShowContent={setCurrentDrawerContent}
              options={drawerOptions}
            />
          </SDrawer>
        </>
      )}
    </ComposeEmailContext.Provider>
  );
};
