import {
  SButton,
  SCheckbox,
  SSearchInput,
  SText
} from '@setvi/shared/components';
import { Box, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Account } from '../../../../../../services/react-query/query/contacts/types';
import { useSelected } from '../../../../../../hooks';
import { DrawerListState } from '../../../../components/list-state';

interface AccountSelectProps {
  accounts: Account[];
  selectedAccounts: Account[];
  searchTerm: string;
  accountsLoading: boolean;
  setSearchTerm: (searchString: string) => void;
  setSelectedAccounts: (accounts: Account[]) => void;
  handleClose: () => void;
}

export const AccountSelect = ({
  accounts,
  selectedAccounts,
  searchTerm,
  accountsLoading,
  setSearchTerm,
  setSelectedAccounts,
  handleClose
}: AccountSelectProps) => {
  const { selected, handleSelect } = useSelected<Account>({
    dataKeyGetter: el => el.Id,
    preselected: selectedAccounts
  });

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Box display="flex" alignItems="center" m={3} mb={2}>
        <SText title="Filter By Accounts" weight="bold" />
        <Box ml={2}>
          <SButton
            variant="text"
            onClick={() => handleSelect(false, selectedAccounts)}
            style={{ padding: 0, fontWeight: 400, height: 20 }}>
            Clear all
          </SButton>
        </Box>
        <Box ml="auto">
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box my={2} mx={3}>
        <SSearchInput defaultValue={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Box sx={{ flex: 1, marginLeft: 10, overflow: 'auto' }}>
        {accountsLoading && <DrawerListState title="Loading" />}
        {!accountsLoading && accounts?.length === 0 && (
          <DrawerListState title="No Accounts Found" />
        )}
        {!accountsLoading &&
          accounts?.map(account => (
            <Box
              key={account.Id}
              display="flex"
              alignItems="center"
              gridGap={10}
              p={2}>
              <SCheckbox
                checked={selected.some(acc => acc.Id === account.Id)}
                onChange={e => handleSelect(e.target.checked, [account])}
              />
              <Box>
                <SText title={account.Name} />
              </Box>
            </Box>
          ))}
      </Box>
      <Divider />
      <Box p={3}>
        <SButton fullWidth onClick={() => setSelectedAccounts(selected)}>
          Apply
        </SButton>
      </Box>
    </Box>
  );
};
