import { memo, useState } from 'react';
import { SDrawer, SDropdownMenu } from '@setvi/shared/components';
import { Resource } from '@setvi/shared/interfaces';
import { Header as DrawerHeader } from '@setvi/shared/components/sdrawer-content/components/header';
import { Box } from '@material-ui/core';
import { createPortal } from 'react-dom';
import { DropdownItemsType, useDropdownResource } from './hooks';

interface DropdownMenuProps {
  item: Resource;
  dropdownItems?: DropdownItemsType;
  refetchLoading?: boolean;
  handleCloseDropdown?: () => void | null;
}

const DropdownMenu = ({
  item,
  dropdownItems,
  refetchLoading,
  handleCloseDropdown
}: DropdownMenuProps) => {
  const [aksAIOpen, setAksAIOpen] = useState<boolean>(false);
  const { createDropdownMenu } = useDropdownResource(
    refetchLoading,
    setAksAIOpen
  );

  return (
    <>
      <SDropdownMenu
        menuItems={createDropdownMenu(item, dropdownItems, handleCloseDropdown)}
      />

      {createPortal(
        <>
          {aksAIOpen && (
            <SDrawer open>
              <Box onClick={e => e.stopPropagation()}>
                <DrawerHeader
                  title={`Ask AI anything about ${item.Name}`}
                  handleClose={() => setAksAIOpen(false)}
                />
              </Box>
              <Box width="500px" flex={1} overflow="hidden">
                <iframe
                  src={`https://kind-forest-088a5160f.5.azurestaticapps.net/setvi/chat?mode=${item.ID === 75876 ? 'marine' : 'cafe'}`}
                  width="100%"
                  height="100%"
                  title="Ask AI"
                  style={{ border: 'none' }}
                />
              </Box>
            </SDrawer>
          )}
        </>,
        document.body
      )}
    </>
  );
};
// 117304
export default memo(DropdownMenu);
