export enum ContactsApi {
  Contacts = '/api/v1.0/users/contacts',
  ImportFromFilestack = '/api/v1.0/users/contacts/import/filestack',
  RecentContacts = '/api/v1.0/users/contacts/recent',
  SuggestedContacts = '/api/v1.0/users/contacts/suggested',
  SalesforceAccounts = 'api/v2/crm/salesforce/accounts',
  SalesforceStates = 'api/v2/crm/salesforce/states',
  SalesforceCities = 'api/v2/crm/salesforce/cities',
  SalesforceContacts = 'api/v2/crm/salesforce/contacts'
}
