import { Box, Theme, makeStyles } from '@material-ui/core';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { getInitials } from '@setvi/shared/utils';
import { SAvatar, SETVIImage, SText } from '@setvi/shared/components';
import { Contact as ContactInterface } from 'Services/Query/Contacts/Types';

interface ContactProps {
  selected?: boolean;
  contact: ContactInterface;
}

interface StyleProps {
  selected: boolean;
}

const originImage: { [key: ContactInterface['Origin']]: string } = {
  0: 'icons/contacts/origin/setvi.svg',
  1: 'icons/contacts/origin/setvi.svg',
  2: 'icons/contacts/origin/salesforce.svg'
};

const useStyle = makeStyles<Theme, StyleProps>(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridGap: spacing(3),
    paddingX: spacing(2),
    cursor: ({ selected }) => (selected ? 'default' : 'pointer'),
    '&:hover': {
      backgroundColor: palette.grey[200]
    }
  }
}));

export const Contact = ({
  selected,
  contact: { FirstName, LastName, CompanyName, CustomerNumber, Origin, Email }
}: ContactProps) => {
  const classes = useStyle({ selected });
  const fullName = `${FirstName} ${LastName}`;
  const companyName = `${CompanyName}${CustomerNumber ? ` (${CustomerNumber})` : ''}`;

  return (
    <Box className={classes.root} p={2} style={{ cursor: 'pointer' }}>
      <Box flex={1} display="flex" alignItems="center">
        <Box mr={2}>
          <SAvatar
            value={getInitials({
              firstName: FirstName,
              lastName: LastName,
              email: Email
            })}
          />
        </Box>
        <Box display="flex" alignItems="center" width="100%">
          <Box display="flex" flexDirection="column" flex={1}>
            {(FirstName || LastName) && (
              <SText fontColor="#171725" title={fullName.trim()} />
            )}
            <SText size="sm" fontColor="#44444F" title={Email} />
            {(CompanyName || CustomerNumber) && (
              <SText size="sm" fontColor="#44444F" title={companyName} />
            )}
          </Box>
          {selected && (
            <Box mx={2} display="flex">
              <CheckRoundedIcon htmlColor="#696974" />
            </Box>
          )}
          <Box
            ml="auto"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #E2E2EA',
              borderRadius: '4px',
              width: 32,
              height: 32,
              bgcolor: '#FFFFFF'
            }}>
            <SETVIImage src={originImage[Origin || 0]} alt="origin" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
