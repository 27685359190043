import { EditorImagesIds, TextColor } from '@setvi/shared/enums';
import { Product } from '@setvi/shared/interfaces';
import { v4 as uuid } from 'uuid';
import {
  selectedProductClassName,
  selectedProductLinkClassName,
  selectedProductTitleClassName
} from './index';

export const getProductCardAsHtml = (
  product: Product,
  manufacturer: boolean
) => {
  const containerWidth = '312px';
  const imageWidth = 'width: 50px; min-width: 50px;';
  const imageHeight = 'height: 37px; min-height: 37px;';
  const fontSize = '14px';
  const textFieldOverflow = `display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-box-orient: vertical; -webkit-line-clamp: 2; word-break: break-word; line-height: 1.1; font-size: ${fontSize};`;
  const productName = `${textFieldOverflow} margin: auto 0; height: min-content; align-self: center; font-weight: 600; cursor: pointer;`;
  // @ts-ignore
  const productLink = product.custom.ecommerceLink?.value;
  const hasPrice = typeof product.price === 'number';

  // This encodeURI method is used for replacing spaces with %20 in the image URL to aviod the image not found issue
  const imageSrc = encodeURI(
    product.imageUrl || product.images[0]?.path || ''
    // 'https://euhd1msp.blob.core.windows.net/public/no-product-image.png'
  );
  const productImage = imageSrc
    ? ` <div style="${imageWidth} ${imageHeight} border-radius: 2px; overflow: hidden; display: flex">
          <img id="${EditorImagesIds.PRODUCT}" src=${imageSrc} alt=${product.name} style="object-fit: contain; max-width: 100%; max-height: 100%;  margin: auto 6px auto auto" />
        </div>`
    : '';

  return `<div id="product-${uuid()}" class="${selectedProductClassName}" style="width: ${containerWidth}; min-width: ${containerWidth}; max-width: ${containerWidth}; border: 1px solid ${TextColor.LightGrey}; border-radius: 12px; padding: 12px 10px">
        <div>    
            <div style="display: flex;">
                ${productImage}
                ${
                  productLink
                    ? ` <a class="${selectedProductLinkClassName}" data-product-href="${productLink}" contenteditable="false" target="_blank" style="${productName} color: #1E96FC;">
                          ${product.name}
                        </a>`
                    : `<p class="${selectedProductTitleClassName}" style="${productName} color: ${TextColor.Black};">${product.name}</p>`
                }
            </div>
            <p style="${textFieldOverflow} color: ${TextColor.Grey}; padding-top: 8px">
                SKU: ${product.sku}
            </p>
        </div>
        <div style="padding: 8px 0 ${hasPrice ? '8px' : '0'} 0; border-top: 1px solid #E6E7E9; margin-top: 8px">
            <p style="${textFieldOverflow} font-size: ${fontSize} color: ${TextColor.Black}">
              MFG: ${product?.vendor?.name || 'Not Available'}
            </p>
            ${!manufacturer ? `<p style="font-size: ${fontSize}; color: ${TextColor.Grey}; padding-top: 6px">${product.manufacturerSku}</p>` : ''}
        </div>
        ${
          hasPrice
            ? `<p style="border-top: 1px solid #E6E7E9; padding-top: 8px; font-size: ${fontSize}; font-weight: 600">${manufacturer ? 'List' : 'Sell'}: ${(
                product?.price || 0
              ).toLocaleString('en-US', {
                currency: 'USD',
                style: 'currency'
              })}</p>`
            : ''
        }
      </div>`;
};
