import { SButton, SSearchInput, SText } from '@setvi/shared/components';
import { Box, Divider, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Location } from '../location-select/location';
import { City } from '../../../../../../services/react-query/query/contacts/types';
import { useSelected } from '../../../../../../hooks';
import { DrawerListState } from '../../../../components/list-state';

interface CitySelectProps {
  cities: City[];
  citiesLoading: boolean;
  searchTerm: string;
  selectedCities: City[];
  setSelectedCities: (cities: City[]) => void;
  handleClose: () => void;
  setSearchTerm: (searchString: string) => void;
}

export const CitySelect = ({
  cities,
  citiesLoading,
  searchTerm,
  selectedCities,
  setSelectedCities,
  handleClose,
  setSearchTerm
}: CitySelectProps) => {
  const { selected, handleSelect } = useSelected<City>({
    dataKeyGetter: el => `${el.City}${el.State}`,
    preselected: selectedCities
  });

  return (
    <Box display="flex" flexDirection="column" height="100%" minHeight={0}>
      <Box display="flex" alignItems="center" m={3} mb={2}>
        <SText title="Filter By City" weight="bold" />
        <Box ml={2}>
          <SButton
            variant="text"
            onClick={() => handleSelect(false, selectedCities)}
            style={{ padding: 0, fontWeight: 400, height: 20 }}>
            Clear all
          </SButton>
        </Box>
        <Box ml="auto">
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box my={2} mx={3}>
        <SSearchInput defaultValue={searchTerm} onChange={setSearchTerm} />
      </Box>
      <Box sx={{ flex: 1, marginLeft: 10, overflow: 'auto' }}>
        {citiesLoading && <DrawerListState title="Loading..." />}
        {!citiesLoading && cities?.length === 0 && (
          <DrawerListState title="No Cities Found" />
        )}
        {!citiesLoading &&
          cities?.map(city => (
            <Location
              key={`${city.City}${city.State}`}
              title={`${city.City}, ${city.State}, ${city.StateCode}`}
              onChange={e => handleSelect(e.target.checked, [city])}
              checked={selected.some(
                c => `${c.City}${c.State}` === `${city.City}${city.State}`
              )}
            />
          ))}
      </Box>
      <Divider />
      <Box p={3}>
        <SButton fullWidth onClick={() => setSelectedCities(selected)}>
          Apply
        </SButton>
      </Box>
    </Box>
  );
};
