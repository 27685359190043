import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'enumsV2';
import { Contact } from 'Services/Query/Contacts/Types';
import { UseEmailFormProps } from 'providersV2/compose-email/interfaces';

import { isContactSelected } from '@setvi/shared/components/sdrawer-content/components/contact/utils';
import { useSuggestedContacts } from '../../suggested-contacts/hook';
import { Chip } from '../../chip';
import { emailChipErrorHandler } from '../utils';

export const useEmailForm = ({
  values,
  setValues,
  closeComposeEmail
}: UseEmailFormProps) => {
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [recipientInputValue, setRecipientInputValue] = useState('');
  const { suggestedContacts, isLoading: isSuggestedContactsLoading } =
    useSuggestedContacts({
      search: recipientInputValue.trim()
    });

  const isSuggestedContactsOpened = useMemo(
    () => recipientInputValue.length > 0 && suggestedContacts?.length > 0,
    [recipientInputValue, suggestedContacts]
  );

  useEffect(() => {
    if (pathname === ROUTES.LOGIN) closeComposeEmail();
  }, [pathname, closeComposeEmail]);

  const chips: Chip[] = useMemo(() => {
    const chipsList: Chip[] = values.sendTo.map(recipient => ({
      label: recipient.Email,
      ...(!!recipient.Id && { Id: recipient.Id }),
      errors: emailChipErrorHandler(recipient.Email)
    }));

    return chipsList;
  }, [values.sendTo]);

  const onChipAdd = useCallback(
    (chip: string) => {
      setValues({
        ...values,
        sendTo: [...values.sendTo, { Email: chip }]
      });
      setRecipientInputValue('');
    },
    [setValues, values]
  );

  const onChipDelete = useCallback(
    (chip: Chip) => {
      setValues({
        ...values,
        sendTo: values.sendTo.filter(
          (recipient: any) =>
            recipient.Id !== chip.Id || recipient.Email !== chip.label
        )
      });
      setRecipientInputValue('');
    },
    [setValues, values]
  );

  const onSuggestedContactSelect = useCallback(
    (contact: Contact) => {
      const isEmailInRecipientList = isContactSelected(contact, values.sendTo);

      if (isEmailInRecipientList) return;

      setValues({
        ...values,
        sendTo: [
          ...values.sendTo,
          {
            Id: contact.Id,
            Email: contact.Email
          }
        ]
      });
    },
    [setValues, values]
  );

  return {
    chips,
    anchorEl,
    suggestedContacts,
    recipientInputValue,
    isSuggestedContactsOpened,
    isSuggestedContactsLoading,

    onChipAdd,
    setAnchorEl,
    onChipDelete,
    setRecipientInputValue,
    onSuggestedContactSelect
  };
};
