import { useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  getSalesforceCities,
  getSalesforceStates
} from '../../../../../../services';
import {
  City,
  State
} from '../../../../../../services/react-query/query/contacts/types';

const CITIES_LIMIT = 30;
export const useLocation = () => {
  const [stateSearchTerm, setStateSearchTerm] = useState<string>('');
  const [citySearchTerm, setCitySearchTerm] = useState<string>('');
  const [selectedStateCodes, setSelectedStateCodes] = useState<
    State['StateCode'][]
  >([]);
  const [selectedCities, setSelectedCities] = useState<City[]>([]);

  const {
    data: stateData,
    isLoading: isStateLoading,
    isFetching: isStateFetching
  } = useQuery(getSalesforceStates());

  const cityQueryVariables = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: CITIES_LIMIT,
      states: selectedStateCodes,
      searchString: citySearchTerm
    }),
    [selectedStateCodes, citySearchTerm]
  );

  const {
    data: citiesData,
    isLoading: citiesLoading,
    isFetching: citiesFetching
  } = useInfiniteQuery(getSalesforceCities(cityQueryVariables));

  return {
    states:
      stateData?.Data?.filter(
        state =>
          state.State?.toLowerCase().includes(stateSearchTerm.toLowerCase()) ||
          state.StateCode?.toLowerCase().includes(stateSearchTerm.toLowerCase())
      ) || [],
    statesLoading: isStateLoading || isStateFetching,
    stateSearchTerm,
    setStateSearchTerm,
    selectedStateCodes,
    setSelectedStateCodes,
    cities: citiesData?.pages?.flatMap(page => page?.Data) || [],
    citiesLoading: citiesLoading && citiesFetching,
    citySearchTerm,
    setCitySearchTerm,
    selectedCities,
    setSelectedCities
  };
};
